import React,{ useRef }  from 'react'
import separatorImage from '../images/separatorBlack.png';
import { FaArrowRight } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';


import emailjs from '@emailjs/browser';

const Contact = () => {
  const { t } = useTranslation();
  const form = useRef();

    const sendEmail = (e) => {
      
    let name=document.getElementById("input-name");
    let email=document.getElementById("input-email");
    let subject=document.getElementById("input-subject");
    let msg=document.getElementById("input-message");
  
    let trimName=name.value.trim();
    let trimEmail=email.value.trim();
    let trimSubject=subject.value.trim();
    let trimMsg=msg.value.trim();

    if((trimName!=null && trimName!="") && (trimEmail!=null && trimEmail!="") && (trimSubject!=null && trimSubject!="") && (trimMsg!=null && trimMsg!="")){
      e.preventDefault();
  
      emailjs.sendForm('service_btvpuzj', 'template_a8wa3by', form.current, 'IWdWq9JktBJoa2Jyz')
        .then((error) => {
          console.log(error.text);
        });

        name.value="";
        email.value="";
        subject.value="";
        msg.value="";
      }
    };
  
  

  



const handleClick=()=>{

  let name=document.getElementById("input-name");
  let email=document.getElementById("input-email");
  let subject=document.getElementById("input-subject");
  let msg=document.getElementById("input-message");

  let trimName=name.value.trim();
  let trimEmail=email.value.trim();
  let trimSubject=subject.value.trim();
  let trimMsg=msg.value.trim();


   if((trimName!=null && trimName!="") && (trimEmail!=null && trimEmail!="") && (trimSubject!=null && trimSubject!="") && (trimMsg!=null && trimMsg!="")){
    Swal.fire({
      background:'#1d1d1d',
      position: 'center-center',
      color:'#ffffff',
      icon: 'success',
      title: 'E-Mail Send Succsessfuly',
      showConfirmButton: false,
      timer: 1500
    })
  }
  else{
    Swal.fire({
      background:'#1d1d1d',
      color:'#ffffff',
      position: 'center-center',
      timer: 2000,
      icon: 'error',
      title: 'Something went wrong',
      text: 'Check the texts you entered',
    })
  }


}
  return (
    <div id='contact' className='w-full h-fit mt-16 flex justify-center text-center items-center flex-col'>
 
        <span className='w-full font-bold text-app-black text-2xl tracking-widest '>{t("Contact Me")}</span>
        <img src={separatorImage} className='items-center mt-8' alt="" />
    <div id='form-container' className='tablet-768:w-2/5 phone-450:w-10/12 w-full tablet-768:min-w-700 p-4 h-fit bg-app-dark mt-12 flex justify-center items-center flex-col'>
        <span className='sm:text-2xl text-xl text-app-white font-bold'>{t("Love To Hear")} <br />{t("Get In Touch")}</span>
        <form className='w-full text-center mt-16' ref={form} onSubmit={sendEmail}>
            <input id='input-name' required={true} className='w-3/4 bg-app-dark mb-6 border-app-gray border-b outline-none h-12 sm:text-xl text-base font-medium text-app-white' name="user_name" placeholder={t("Name")} type="text"/>
            <input id='input-email' required={true} className='w-3/4 bg-app-dark my-6 border-app-gray border-b outline-none h-12 sm:text-xl text-base font-medium text-app-white' name="user_email" placeholder={t("Email Adress")} type="email"/>
            <input id='input-subject' required={true} className='w-3/4 bg-app-dark my-6 border-app-gray border-b outline-none h-12 sm:text-xl text-base font-medium text-app-white' name="user_subject" placeholder={t("Subject")} type="text"/>
            <textarea id='input-message' required={true} className='w-3/4 bg-app-dark my-6 border-app-gray border-b resize-none outline-none sm:text-xl text-base font-medium h-40 text-app-white' name='message' placeholder={t("Your Message")} ></textarea>
        <br />
        <div className='flex w-fit h-14 flex-row m-auto text-center justify-center items-center py-1 px-2 hover:scale-110 ease-in-out duration-300 rounded-lg'>
        <input onClick={handleClick} type="submit" value={t("Send")} className='my-6 cursor-pointer text-app-white font-medium sm:text-2xl text-xl ' />
        <FaArrowRight className='ml-4 text-app-white '/>
        </div>
        
        </form>
    </div>
  
    </div>
  )
}

export default Contact