import React from 'react'
// import man from '../images/man.png';
import { AiOutlineGithub } from 'react-icons/ai';
import { FaLinkedinIn } from 'react-icons/fa';
import { BsMouseFill } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';


const About = () => {

  const { t } = useTranslation();
 
  return (
    
    <div id='about' className='main w-full z-10 h-screen flex justify-center items-center bg-app-background-gray'>

        <div id='path' className='w-1/2 right-0 top-0 h-screen absolute z-0'></div>
        <div id='scrool-down' className='absolute desktop:top-[90%] top-10  desktop:right-[2%] right-3 text-center justify-start items-center rounded-lg z-40 animate-bounce md:block hidden'>
          <a href="#AboutMessage" className='text-center w-full h-full items-center flex justify-center'>
          <span className='text-base font-bold text-app-white '>{t('Scrool Down')}</span>
          <BsMouseFill className='w-10 h-auto mx-2 text-app-white '/>
          </a>
        </div>
            <div id='about-left' className='md:w-1/2 w-full flex z-20 md:bg-opacity-0 bg-app-black bg-opacity-60 md:h-full h-fit bottom-0  md:py-0 py-4 md:static absolute md:justify-center justify-start flex-col md:items-center items-start'>
                
                <div className='text-left z-10 md:ml-0 ml-4 slide-left'>
                <div>
                <h2 className='md:text-3xl text-xl md:text-app-black text-app-white font-medium md:mb-8 mb-6'>{t('i-am')}</h2>
                <h1 className='md:text-8xl w-fit text-4xl md:text-app-black text-app-white font-bold'>Eren Koç</h1>
                <h2 className='md:text-4xl text-2xl md:text-app-gray text-app-white font-medium '>{t('Front-End')}</h2>
                </div>
                <div id='social-medias' className='md:mt-16 mt-6 flex flew-row'>
              <a href="https://linkedin.com/in/eren-koç" target='_blank' rel="noreferrer" className='w-fit h-fit hover:scale-110 ease-in-out duration-300'><div className='md:w-12 md:h-12 rounded md:bg-app-socailmedias-bg flex justify-center md:border-b md:border-app-gray md:mr-4 mr-2 items-center'><FaLinkedinIn className='md:text-app-black text-app-white' size={27}/></div></a>  
             <a target='_blank' rel="noreferrer" href="https://github.com/Eren-Koc" className='w-fit h-fit hover:scale-110 ease-in-out duration-300' ><div className='md:w-12 md:h-12 rounded md:bg-app-socailmedias-bg flex justify-center md:border-b md:border-app-gray  md:mx-4 mx-2 items-center'><AiOutlineGithub  className='md:text-app-black text-app-white' size={27}/></div></a>
              

                </div>
                </div>
                
            </div>
            <div id='about-right' className='z-10 md:w-1/2 md:justify-start w-full flex max-md:h-screen pt-8 md:static items-end relative justify-end'>
              <img className='h-full w-auto phone-450:object-contain object-cover md:h-[425px] slide-right md:rounded-full md:overflow-hidden' alt="eren koç" /></div>
        
    </div>
  )
}

export default About