import React from 'react'
import PropTypes from 'prop-types';
import ProgressBar from './ProgressBar';
import '../index.css'

export const SingleSkill = (data) => {

  const {SkillName,SkillImg,SkillProgressValue,SkillBackgroundImg} = data.data;

  return (
    <div className='single-skill-card w-full h-[250px] rounded-lg relative flex flex-col items-center justify-center m-auto'>
        <img className='relative w-full h-full rounded-lg object-cover' src={SkillBackgroundImg} alt="" />
        <div className='bg-darkness z-[5] absolute left-0 top-0 w-full h-full bg-app-black opacity-40 rounded-lg'></div>

        <div className='flex flex-row absolute z-10 top-3 left-3 justify-center items-center'>
        <img src={SkillImg} className='w-12 h-12' alt="" />
        <span className='ml-2 font-bold text-xl text-app-white font-poppins'>{SkillName}</span>
        </div> 

        <div className='my-4 absolute z-20 flex'>
         <ProgressBar SkillProgressValue={SkillProgressValue}/>    
        </div>

    </div>
  )
}

SingleSkill.propTypes = {
    SkillName: PropTypes.string,
    SkillImg:PropTypes.string,
    SkillProgressValue:PropTypes.number,
  };

export default SingleSkill