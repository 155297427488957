import React from 'react'
import { AiFillHome,AiOutlineMail } from 'react-icons/ai';
import PropTypes from 'prop-types';
import { BsImages } from 'react-icons/bs';
import { MdComputer } from 'react-icons/md';
import { CgWebsite } from 'react-icons/cg';
import { useTranslation } from 'react-i18next';

const PhoneNav = ({setVisibleServices}) => {
  const { t } = useTranslation();
  return (
    <div className='w-full fixed z-40 left-0 h-16 top-[calc(100%_-_4rem)] bg-app-white sm:hidden flex justify-start items-center'>
    <div id='Home-nav' className='w-1/5 h-full flex justify-center items-center flex-col text-xxs'>
    <a href="#" className='w-full h-full text-center flex justify-center flex-col items-center'>
    <AiFillHome size={24} color='black'/>
    <span className='mt-1 m_title lowercase'>{t('Home')}</span>
    </a>
    </div>
    <div id='skills-nav' className='w-1/5 h-full flex justify-center items-center text-xxs flex-col'>
    <a href="#skills" className='w-full h-full text-center flex justify-center flex-col items-center'>
    <MdComputer size={24} color='black'/>
    <span className='mt-1 m_title lowercase'>{t('Skills')}</span>
    </a>
    </div>
    <div id='Portfolio-nav' className='w-1/5 h-full flex justify-center items-center text-xxs flex-col'>
    <a href="#portfolio" onClick={()=>setVisibleServices(false)} className='w-full h-full text-center flex justify-center flex-col items-center'>
    <BsImages size={24} color='black'/>
    <span className='mt-1 m_title lowercase'>{t('Portfolio')}</span>
    </a>
    </div>
 
    <div id='Portfolio-nav' className='w-1/5 h-full flex justify-center items-center text-xxs flex-col'>
    <a href="#services" onClick={()=>setVisibleServices(true)} className='w-full h-full text-center flex justify-center flex-col items-center'>
    <CgWebsite size={24} color='black'/>
    <span className='mt-1 m_title lowercase'>{t('Services')}</span>
    </a>
    </div>

    <div id='Contact-me-nav' className='w-1/5 h-full flex justify-center items-center text-xxs flex-col'>
    <a href="#contact" className='w-full h-full text-center flex justify-center flex-col items-center'>
    <AiOutlineMail size={24} color='black'/>
    <span className='mt-1 m_title lowercase'>{t('Contact Me')}</span>
    </a>
    </div>
    
    </div>
  )
}
PhoneNav.propTypes = {
  visibleServices: PropTypes.bool,
  setVisibleServices:PropTypes.func
};

export default PhoneNav