import React from 'react'
import { HiMenuAlt2,HiOutlineX } from 'react-icons/hi';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from './LanguageSwitcher';
import PropTypes from 'prop-types';

const Header = ({setVisibleServices}) => {
  const { t } = useTranslation();
  return (
  <nav className='w-full h-fit py-1 flex-row fixed sm:left-3 left-1 top-1 sm:top-3 z-50 flex justify-start items-center'>
    <div className='group menu-container w-fit h-fit justify-center items-center p-2  rounded-sm sm:flex hidden'>
    <div className='menu p-2 rounded-lg bg-app-socailmedias-bg shadow-lg cursor-pointer'>
    <HiMenuAlt2 id='menu-open' title='Menü' className='block group-hover:hidden' size={32} color='black'/>
    <HiOutlineX id='menu-close' title='Menü' className='hidden group-hover:block' size={32} color='black'/>
    </div> 
    <div id='menu-nav' className='group-hover:block hidden menu-bar px-2 py-1 ml-4 bg-app-socailmedias-bg rounded'>
        <ul className='flex flex-wrap phone-450:flex-row flex-col text-black'>
            <a href="#" className='hover:underline underline-offset-4 rounded-md text-large text-app-black ease-in-out duration-300 '><li className='m-2 lowercase m_title'>{t('Home')}</li></a>
            <a href="#portfolio" onClick={()=> setVisibleServices(false)} className='hover:underline underline-offset-4 rounded-md text-large text-app-black ease-in-out duration-300'><li className='m-2 lowercase m_title'>{t('Portfolio')}</li></a>
            <a href="#services" onClick={()=> setVisibleServices(true)} className='hover:underline underline-offset-4 rounded-md text-large text-app-black ease-in-out duration-300'><li className='m-2 lowercase m_title'>{t('Services')}</li></a>
            <a href="#skills" className='hover:underline underline-offset-4 rounded-md text-large  text-app-black ease-in-out duration-300'><li className='m-2 lowercase m_title'>{t('Skills')}</li></a>
            <a href="#contact" className='hover:underline underline-offset-4 rounded-md text-large text-app-black ease-in-out duration-300'><li className='m-2 lowercase m_title'>{t('Contact Me')}</li></a>
            </ul></div> 
    
    </div>
    <LanguageSwitcher/>
    </nav>
  
  )
}

Header.propTypes = {
  visibleServices: PropTypes.bool,
  setVisibleServices:PropTypes.func
  };

export default Header