import React,{useState,useEffect} from 'react'
import { useTranslation } from 'react-i18next';
import {IoIosArrowDown} from 'react-icons/io';

const LanguageSwitcher = () => {
    const [isElementsCreated, setIsElementsCreated] = useState(false);
    const [Language,setLanguage] = useState("en");
    const {i18n} = useTranslation();

    useEffect(() => {
        const selectedFlag = document.getElementById("selected-flag");
        const selectedLang = document.querySelector("#selected-lang");
        const optionFlag = document.getElementById("option-flag");
        const optionLang = document.querySelector("#option-lang");
    
        if (selectedFlag && selectedLang && optionFlag && optionLang) {
          setIsElementsCreated(true);
        }
      }, []);
    
      const selectedFlag = document.getElementById("selected-flag");
      const selectedLang = document.querySelector("#selected-lang");
      const optionFlag = document.getElementById("option-flag");
      const optionLang = document.querySelector("#option-lang");

      
    const changeLang =() =>{
        if (isElementsCreated) {
       
       if(Language==="tr"){
        setLanguage("en");
        i18n.changeLanguage("en");
        selectedFlag.src="https://flagsapi.com/US/flat/32.png";
        optionFlag.src="https://flagsapi.com/TR/flat/32.png";
        optionLang.textContent="Türkçe";
        selectedLang.textContent="English";
       }
        else if(Language==="en"){
        setLanguage("tr");
        i18n.changeLanguage("tr");
        optionFlag.src="https://flagsapi.com/US/flat/32.png";
        selectedFlag.src="https://flagsapi.com/TR/flat/32.png";
        selectedLang.textContent="Türkçe";
        optionLang.textContent="English";
       }
    }
        

    
        
    };

  return (
    <>
    <div className='group cursor-pointer tablet-768:w-[150px] w-fit h-[48px] flex justify-center items-center bg-app-socailmedias-bg flex-row p-2 rounded-md  shadow-sm hover:rounded-none'>
        <img id='selected-flag' src="https://flagsapi.com/US/flat/32.png" className='rounded-xl phone-450:mr-2'  alt="" />
        <span id='selected-lang' className='text-app-dark text-base-sm font-bold mr-1 tablet-768:block hidden'>English</span>
        <IoIosArrowDown className='max-sm:hidden group-hover:rotate-180 text-app-dark ml-2'/>
    <div className='other-lang absolute bottom-[-36px] tablet-768:w-[150px] h-[48px] bg-app-socailmedias-bg p-2 hover:bg-app-hover flex-row justify-center sm:pr-8 items-center hidden group-hover:flex duration-300 ease-in' onClick={()=> changeLang()}>
    <img id='option-flag' src="https://flagsapi.com/TR/flat/32.png" className='rounded-xl phone-450:mr-2 max-phone-450:w-16'  alt="" />
        <span id='option-lang' className='text-app-dark text-base-sm font-bold mr-1 tablet-768:block hidden'>Türkçe</span>
    </div>
    </div>
   
    </>
  )
}

export default LanguageSwitcher