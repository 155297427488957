import React from 'react'

import { AiOutlineGithub,AiTwotoneMail } from 'react-icons/ai';
import { FaLinkedinIn } from 'react-icons/fa';
import { BiArrowToTop } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';


const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className='w-full h-fit py-8 bg-app-dark mt-16 flex justify-center items-center flex-col'>
     <a href="#"><div id='back-to-top' className='group relative flex flex-col w-fit h-fit text-center items-center justify-center hover:translate-y-[-0.5rem] ease-in-out duration-500'>
      <BiArrowToTop className='top-0 mb-1 w-8 h-auto text-app-white'/>
      <span className='text-app-white tracking-widest'>{t("Back To Top")}</span>
      </div></a>
      <div className="social-medias w-fit h-fit flex-row mt-12 flex-wrap justify-center items-center">
        <a target='_blank' rel="noreferrer" href="https://linkedin.com/in/Eren-Koç" className='mx-3 inline-flex hover:scale-110 ease-in-out duration-300'>
          <FaLinkedinIn className='text-app-white w-6 h-auto'/>
        </a>
        <a target='_blank' rel="noreferrer" href="https://github.com/Eren-Koc" className='mx-3 inline-flex hover:scale-110 ease-in-out duration-300'>
          <AiOutlineGithub className='text-app-white w-6 h-auto'/>
        </a>
     
        <a target='_blank' rel="noreferrer" href="mailto: mail@erenkoc.net" className='mx-3 inline-flex hover:scale-110 ease-in-out duration-300'>
          <AiTwotoneMail className='text-app-white w-6 h-auto'/>
        </a>
      </div>
      <div className='mt-12 flex flex-row w-fit h-fit'>
        <span className='font-bold mr-2 text-app-white text-base'>© 2023 Eren Koç</span>
        <span className='font-medium mr-2 text-app-white text-base'>{t("Copyright")}</span>
      </div>
    </footer>
  )
}

export default Footer