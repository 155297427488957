
import html5 from './images/html5.svg';
import htmlbg from './images/html-bg.jpg';
import js from './images/js.svg';
import jsbg from './images/js-bg.jpg';
import css3 from './images/css3.svg';
import cssbg from './images/css-bg.jpg';
import react from './images/react.svg';
import reactbg from './images/react-bg.jpg';
import tailwind from './images/tailwind.png';
import tailwindbg from './images/tailwind-bg.webp';
import vuejs from './images/vuejs.png';
import vuejsbg from './images/vuejs-bg.jpg';
import typescript from './images/typescript.svg';
import typescriptbg from './images/typescript-bg.jpg';
import bootstrap from './images/bootstrap.svg';
import bootstrapbg from './images/bootstrap-bg.jpg';
import mysql from './images/mysql.svg';
import mysqlbg from './images/mysql-bg.png';
import cplusplus from './images/c++.svg';
import cplusplusbg from './images/c++-bg.jpg';
import csharp from './images/csharp.png';
import csharpbg from './images/csharp-bg.jpg';
import php from './images/php.png';
import phpbg from './images/php-bg.jpg';

export const SkillsUsing =[
        {
          id:1,
         SkillName:'Html 5',
         SkillBackgroundImg:htmlbg,
         SkillImg:html5,
         SkillProgressValue:90,  
         },

          {
          id:2,
          SkillName:'Css 3',
          SkillBackgroundImg:cssbg,
          SkillImg:css3,
          SkillProgressValue:80,
          },
          {
            id:3,
          SkillName:'Javascript',
          SkillBackgroundImg:jsbg,
          SkillImg:js,
          SkillProgressValue:60,  
          },
          {
            id:4,
          SkillName:'React',
          SkillBackgroundImg:reactbg,
          SkillImg:react,
          SkillProgressValue:50,  
          },
          {
            id:5,
          SkillName:'Tailwind',
          SkillBackgroundImg:tailwindbg,
          SkillImg:tailwind,
          SkillProgressValue:70,    
          },
          {
            id:9,
          SkillName:'Bootstrap',
          SkillBackgroundImg:bootstrapbg,
          SkillImg:bootstrap,    
          SkillProgressValue:50,  
          }  
]
 export const SkillsLearning =[
     
          {
            id:8,
          SkillName:'Typescript',
          SkillBackgroundImg:typescriptbg,
          SkillImg:typescript,
          SkillProgressValue:25,      
          },
          {
            id:14,
          SkillName:'Vue.js',
          SkillBackgroundImg:vuejsbg,
          SkillImg:vuejs,
          SkillProgressValue:30,      
          },
          
 ]
 export const SkillsOthers =[
          {
          id:10,
          SkillName:'PHP',
          SkillBackgroundImg:phpbg,
          SkillImg:php,
          SkillProgressValue:30,      
          },
          {
          id:11,
          SkillName:'C#',
          SkillBackgroundImg:csharpbg,
          SkillImg:csharp,
          SkillProgressValue:45 ,      
          },
          {
          id:12,
          SkillName:'C++',
          SkillBackgroundImg:cplusplusbg,
          SkillImg:cplusplus,
          SkillProgressValue:25,      
          },
          {
          id:13,
          SkillName:'MYSQL',
          SkillBackgroundImg:mysqlbg,
          SkillImg:mysql,
          SkillProgressValue:30,      
          }
       
]