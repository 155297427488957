import React from 'react';
import '../index.css';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/joy/CircularProgress';

export default function ProgressBar(props){
  const { SkillProgressValue } = props;
  return (

<>

<CircularProgress variant="plain" color="success" className="" sx={{ '--CircularProgress-size': '120px',"--CircularProgress-progressThickness": "8px"}} determinate value={SkillProgressValue}>
    <span className='text-app-white font-bold text-2xl'>{SkillProgressValue}%</span>
</CircularProgress>
</>
  )
 
}
ProgressBar.propTypes = {
  SkillProgressValue:PropTypes.number,
};