import React from 'react'
import SoftwareIcon from '../images/software-icon.png';
import { useTranslation } from 'react-i18next';
const AboutMessage = () => {
  const { t } = useTranslation();
  return (
    <div id='AboutMessage' className='w-full bg-app-dark h-fit flex justify-center items-start md:px-16 px-4 flex-col text-left py-8  relative bg-no-repeat desktop:bg-right bg-center desktop:bg-auto bg-contain bg-none' style={{ backgroundImage: `url(${SoftwareIcon})`}}>
        <span id='about-title' className='text-2xl text-app-white font-bold sm:mb-6 mb-4 tracking-widest z-10'>{t('About Me Title')}</span>
        <span id='bio' className='text-base text-medium text-app-white desktop:w-4/5 w-full z-10'>
        {t('About Me')}
</span>

    </div>
  )
}

export default AboutMessage

