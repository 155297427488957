import React from 'react'
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import react from '../images/react.svg';
import tailwind from '../images/tailwind.png';
import banner from '../images/portfolio-banner.png';


const Services = ({visibleServices,setVisibleServices}) => {
    const { t , i18n } = useTranslation();
  

    const getPrice=(index)=>{
      let subject=document.getElementById("input-subject");
      let msg=document.getElementById("input-message");

   if(index==0){
    if(i18n.language=="tr"){
      subject.value="Servisler - Orta Seviye";
      msg.value="Merhaba, orta seviye servisiniz hakkında fiyat bilgisi almak istiyorum.";
    }
    else{
      subject.value="Services - Mid level";
      msg.value="Hello, I'm interested in your mid level service. Can I get price?";
    }
   }
   else{
    if(i18n.language=="tr"){
      subject.value="Servisler - Profesyonel Seviye";
    msg.value="Merhaba, profesyonel seviye servisiniz hakkında fiyat bilgisi almak istiyorum.";
    }
    else{
      subject.value="Services - Professional level";
      msg.value="Hello, I'm interested in your professional level service. Can I get price?";
    }
   }

    }
    

  return (
    <div id='services'>
          <div id='portfolio-banner' className='w-full mt-16 flex justify-center flex-col relative items-center'>
        <div className='flex z-10 w-fit flex-row items-center phone-450:absolute phone-450:gap-16 gap-3'>
        <span className='border-b border-app-dark/0 py-2 px-4 hover:border-app-dark/100 text-2xl cursor-pointer' onClick={()=> setVisibleServices(!visibleServices)}>{t("Portfolio")}</span>
        <span className='border-b-2 py-2 px-4 font-bold text-2xl cursor-pointer'>{t("Services")}</span>
        </div>
       <img src={banner} alt="" className='relative z-0 phone-450:block hidden' />
    </div>
    <div className='container-services mt-4 flex w-full px-4 gap-16 justify-center flex-wrap'>

      <div className='group hover:bg-app-hover hover:shadow-lg flex shadow-md flex-col w-1/2 max-sm:w-full sm:w-[80%] md:max-w-[550px] p-4 max-sm:p-2 h-fit border border-app-dark duration-300 rounded-xl'>
        <span className='font-bold text-2xl'>{t("service-mid-title")}</span>
        <span className=' text-app-gray'>{t("service-page-range")} 1 - 6</span>
        <div className='mt-2 w-full py-4 bg-app-socailmedias-bg px-2  rounded-md italic'>{t("service-preface")}</div>
        <span className='font-semibold mt-4 text-xl'>{t("service-offered")}</span>
        <ul className='list-disc px-4'>
          <li className='my-2'>{t("service-item-1")}</li>
          <li className='my-2'>{t("service-item-2")}</li>
          <li className='my-2'>{t("service-item-3")}</li>
        </ul>
        <div className='w-full max-sm:flex-col gap-4 mt-4 flex flex-row '>
          <div className='w-1/2 max-sm:w-full flex flex-col bg-app-socailmedias-bg p-2 rounded-md'>
         <div className='flex items-center gap-2'>
          <img src={react} className='w-7 h-auto' alt="" />
          <span className='font-semibold'>React</span>
         </div>
         <span className='text-base-sm mt-1 italic'>{t("service-react")}</span>
          </div>
        
          <div className='w-1/2 max-sm:w-full flex flex-col bg-app-socailmedias-bg  p-2 rounded-md'>
         <div className='flex items-center gap-2'>
          <img src={tailwind} className='w-7 h-auto' alt="" />
          <span className='font-semibold'>Tailwindcss</span>
         </div>
         <span className='text-base-sm mt-1 italic'>{t("service-tailwindcss")}</span>
          </div>
        </div>
        <a className='m-auto w-fit h-fit' href="#contact"><input type="button" onClick={()=> getPrice(0)} value={t("service-get-price")} className='w-1/2 py-4 m-auto cursor-pointer min-w-[200px] hover:bg-app-dark/90 duration-300 bg-app-dark text-app-white rounded-lg font-bold mt-8 '  /></a>
      </div>
    

      <div className='group hover:bg-app-hover hover:shadow-lg flex shadow-md flex-col w-1/2 max-sm:w-full sm:w-[80%] md:max-w-[550px] p-4 max-sm:p-2 h-fit border border-app-dark duration-300 rounded-xl'>
        <span className='font-bold text-2xl'>{t("service-professional-title")}</span>
        <span className=' text-app-gray'>{t("service-page-range")} 1 - 12</span>
        <div className='mt-2 w-full py-4 bg-app-socailmedias-bg px-2  rounded-md italic'>{t("service-preface")}</div>
        <span className='font-semibold mt-4 text-xl'>{t("service-offered")}</span>
        <ul className='list-disc px-4'>
          <li className='my-2'>{t("service-item-1")}</li>
          <li className='my-2'>{t("service-item-2")}</li>
          <li className='my-2'>{t("service-item-3")}</li>
        </ul>
        <div className='w-full max-sm:flex-col gap-4 mt-4 flex flex-row '>
          <div className='w-1/2 max-sm:w-full flex flex-col bg-app-socailmedias-bg p-2 rounded-md'>
         <div className='flex items-center gap-2'>
          <img src={react} className='w-7 h-auto' alt="" />
          <span className='font-semibold'>React</span>
         </div>
         <span className='text-base-sm mt-1 italic'>{t("service-react")}</span>
          </div>
        
          <div className='w-1/2 max-sm:w-full flex flex-col bg-app-socailmedias-bg  p-2 rounded-md'>
         <div className='flex items-center gap-2'>
          <img src={tailwind} className='w-7 h-auto' alt="" />
          <span className='font-semibold'>Tailwindcss</span>
         </div>
         <span className='text-base-sm mt-1 italic'>{t("service-tailwindcss")}</span>
          </div>
        </div>
        <a className='m-auto w-fit h-fit' href="#contact"><input type="button" onClick={()=> getPrice(1)} value={t("service-get-price")} className='w-1/2 py-4 m-auto cursor-pointer min-w-[200px] hover:bg-app-dark/90 duration-300 bg-app-dark text-app-white rounded-lg font-bold mt-8 '  /></a>
      </div>
    </div>
    </div>
  )
}

Services.propTypes = {
  visibleServices: PropTypes.bool,
  setVisibleServices:PropTypes.func
  };

export default Services