import React from 'react'
import separatorImage from '../images/separatorBlack.png';
import ImageSwiper from './ImageSwiper';
import { useTranslation } from 'react-i18next';

const Skills = () => {
  const { t } = useTranslation();


  return (
    <div id='skills'  className=' w-full py-2 mt-16'>
    <div className='skills-title w-full mb-7 text-center justify-center items-center flex flex-col'>
    <span className='text-2xl text-app-black font-bold tracking-widest'>{t("Skills")}</span>
        <img src={separatorImage} className='items-center mt-8' alt="" />
    </div>
    <ImageSwiper/>   

    </div>
  
  )

 
}

export default Skills