import './App.css';
import './index.css';
import React, { useState } from 'react';
import Menu from './components/Menu';
import PhoneNav from './components/PhoneNav';
import About from './components/About';
import AboutMessage from './components/AboutMessage';
import Skills from './components/Skills';
import ProjectsPortfolio from './components/ProjectsPortfolio';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Services from './components/Services';

// import LanguageSwitcher from './components/LanguageSwitcher';


function App() {

  const [visibleServices,setVisibleServices] = useState(false);
  
  return (
<div className='main w-full h-fit min-h-screen phone-450:pb-0 pb-16 bg-app-background-gray'>

   <Menu visibleServices={visibleServices} setVisibleServices={setVisibleServices}/>
   {/* <LanguageSwitcher/>  */}
   <About/>
   <AboutMessage/>
   <Skills/>
   {visibleServices!==true ? <ProjectsPortfolio visibleServices={visibleServices} setVisibleServices={setVisibleServices} /> : <Services visibleServices={visibleServices} setVisibleServices={setVisibleServices}/>}
   {/* <ProjectsPortfolio/> */}
   <Contact/>
   <Footer/>
   <PhoneNav visibleServices={visibleServices} setVisibleServices={setVisibleServices}/>
   </div>
  );
}

export default App;
